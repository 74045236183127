(function ($) {
/**
 * Typeahead: clear all the things
 */
  $(document).on('endeca.typeahead.clearall', function () {
    var $searchForm = $('.js-end-esearch-nav__form'),
      $searchField = $("input[name='search']", $searchForm),
      $results = $('.esearch-nav__suggestions ul', $searchForm);

    // Reset everything
    $searchField.val('');
    $results.empty();
    $searchForm.removeClass('gnav-search--suggestions gnav-search--no-suggestions');
  });

  /**
 * Typeahead: init event handler
 */
  // $(document).on('endeca.typeahead.init', function(e) {
  // });

  /**
 * Typeahead: keyup event handler
 */
  $(document).on('endeca.typeahead.keyup', function () {
    var $searchForm = $('.js-end-esearch-nav__form'),
      $searchField = $("input[name='search']", $searchForm),
      $val = $searchField.val();

    if ($val.length < 1) {
      $(this).trigger('endeca.typeahead.clearall');
    }
  });

  /**
 * Typeahead: close event handler
 */
  $(document).on('endeca.typeahead.close', function () {
    $(this).trigger('endeca.typeahead.clearall');
  });

  /**
 * Typeahead: search complete event handler
 */
  $(document).on('endeca.typeahead.complete', function (e, hasResults) {
    var $searchForm = $('.js-end-esearch-nav__form');

    $searchForm.removeClass('esearch-nav--suggestions esearch-nav--no-suggestions');
    if (hasResults) {
      $searchForm.addClass('esearch-nav--suggestions');
    } else {
      $searchForm.addClass('esearch-nav--no-suggestions');
    }
  });
})(jQuery);
